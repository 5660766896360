namespace eh {


    export class EhFilterController {

        static ehInit($base: JQuery<HTMLElement>, isSnippetRequest: boolean): void {
            $(`.${EhFilterController.FILTER_WRAPPER}`, $base).each((index: number, element: HTMLElement) => {
                new EhFilterController(element);
            });
            if (!isSnippetRequest) {
                $('body').on('mousedown', '.ehel-pill', function (event) {
                    event.currentTarget.classList.add('focus-click');
                });
                $('body').on('blur', '.ehel-pill', function (event) {
                    event.currentTarget.classList.remove('focus-click');
                });
            }
        }


        public static FILTER_WRAPPER: string = 'ehel-product-finder';
        public static FILTER_WRAPPER_CLASSNAME: string = 'ehel-filter-wrapper';
        public static FILTER_CLASSNAME: string = 'ehel-filter--filter';

        public static FILTER_SELECTION_CLASSNAME: string = 'ehel-filter--filters-selection';
        public static FILTER_SELECTION_LIST_CLASSNAME: string = 'ehel-filter--filters-selection-list';
        public static FILTER_SELECTION_LIST_ITEMS_CLASSNAME: string = 'ehel-filter--filters-selection-list-items';

        public static FILTER_SELECTED_CLASSNAME: string = 'ehel-product-finder--filters-selected';
        public static NAVIGATION_CLASSNAME: string = 'eh-mobile-header-navigation-target-container';


        public static FILTER_REMOVE_SINGLE_CLASSNAME: string = 'ehel-filter-filters-remove-single';

        public static FILTER_FILTERS_OPEN_CLASSNAME: string = 'ehel-filter--menu-open';
        public static FILTER_FILTERS_CLOSE_CLASSNAME: string = 'ehel-filter--menu-close';

        public static SPINNER_CLASSNAMES: string = 'icon-ehel-spinner ehtw-animate-spin';
        public static REMOVE_CLASSNAMES: string = 'icon-ehel-cancel';
        public static ARROW_DOWN_CLASSNAMES: string ='icon-ehel-arrow-down';
        public static ARROW_UP_CLASSNAMES: string ='icon-ehel-arrow-up';

        public static ICON_CLASSNAME: string = 'ehel-icon';
        public static STICKY_CLASSNAME: string = 'is-sticky';


        private readonly el: HTMLElement | null | undefined;
        private readonly form: HTMLFormElement | null | undefined;
        private filters: NodeListOf<HTMLElement> | null | undefined;
        private filtersSelected: HTMLElement | null | undefined;
        private navigation: HTMLElement | null | undefined;
        private inputs: NodeListOf<HTMLInputElement> | null | undefined;
        private activeFiltersButtons: NodeListOf<HTMLElement> | null | undefined;
        private openButtons: NodeListOf<HTMLElement> | null | undefined;
        private readonly closeButtons: NodeListOf<HTMLElement> | null | undefined;
        private resetButtons: NodeListOf<HTMLElement> | null | undefined;
        private submitButtons: NodeListOf<HTMLElement> | null | undefined;
        private readonly resetFormHandler: (e: UIEvent) => void | null |undefined;

            private menu: HTMLElement | null | undefined;

        private vm: EhFilterViewModel | null | undefined = null;

        private root: HTMLElement = document.scrollingElement as HTMLElement;

        constructor(private readonly base: HTMLElement | null) {

            this.el = this.base; // ?.querySelector(`.${EhFilterController.FILTER_WRAPPER}`);
            this.form = this.el?.querySelector("form");
            this.menu = this.el?.querySelector(`.${EhFilterController.FILTER_SELECTION_CLASSNAME}`);
            this.closeButtons = this.el?.querySelectorAll(`.${EhFilterController.FILTER_FILTERS_CLOSE_CLASSNAME}`);
            this.navigation = (document as any).querySelector(`.${EhFilterController.NAVIGATION_CLASSNAME}`);
            this.initDynamics();
            // console.log(this.openButtons, this.menu);

            if (!this.el || !this.filters || !this.form || !this.openButtons || !this.closeButtons || !this.menu) {
                return;
            }

            this.vm = new EhFilterViewModel(
                () => this.filters,
                this.menu
            );
            const that=this;
            this.resetFormHandler = (e: UIEvent) => that.resetForm(e)
            this.init();
        }

        private initDynamics() {
            this.filtersSelected = this.el?.querySelector(`.${EhFilterController.FILTER_SELECTED_CLASSNAME}`);
            this.openButtons = this.el?.querySelectorAll(`.${EhFilterController.FILTER_FILTERS_OPEN_CLASSNAME}`);
            this.activeFiltersButtons = this.el?.querySelectorAll(`.${EhFilterController.FILTER_REMOVE_SINGLE_CLASSNAME}`);
            this.filters = this.el?.querySelectorAll(`.${EhFilterController.FILTER_CLASSNAME}`);
            this.inputs = this.el?.querySelectorAll(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME} input`);
            this.resetButtons = this.el?.querySelectorAll('.ehel-product-finder-trigger-form-reset');
            this.submitButtons = this.el?.querySelectorAll('.ehel-product-finder-ajax-submit');
        }

        private init(): void {
            this.registerControls();
            this.registerDynamicControls();
        }

        private registerControls(): void {
            this.root.addEventListener('click', this.onScrollPageClicked);
            this.closeButtons?.forEach(btn => {
                btn.addEventListener('click', this.onCloseMenuClick);
            });

            document.addEventListener('scroll', this.onScrollChange, {
                passive: true,
            });

        }

        private registerDynamicControls(): void {
            this.openButtons?.forEach(btn => {
                btn.addEventListener('click', this.onOpenMenuClick);
            });
            this.activeFiltersButtons?.forEach(btn => {
                btn.addEventListener('click', this.removeFilter);
            })
            this.filters?.forEach(filter => {
                // console.log("adding  filter handler to:", filter);
                filter.addEventListener('click', this.doFilterClick);
            });
            this.inputs?.forEach(input => {
                input.addEventListener('change', this.onChange);
            });

            const that = this;
            this.resetButtons?.forEach(button => button.removeEventListener('click', this.resetFormHandler));
            this.resetButtons?.forEach(button => button.addEventListener('click', this.resetFormHandler));
            this.submitButtons?.forEach(x => x.removeEventListener('click', this.onCloseMenuClick));
            this.submitButtons?.forEach(x => x.addEventListener('click', this.onCloseMenuClick));
        }


        private onScrollChange: () => void = (): void => {
            if (this.filtersSelected && this.navigation) {
                const offset: number = eh.ScrollPage.getScrollRoot().scrollTop() || 0;

                if(!eh.Breakpoints.getInstance().isMobile || this.filtersSelected.offsetTop - offset + this.navigation.offsetHeight > 0) {
                    this.filtersSelected.classList.remove(EhFilterController.STICKY_CLASSNAME);
                } else {
                    this.filtersSelected.classList.add(EhFilterController.STICKY_CLASSNAME);
                }
            }
        }


        private  resetForm(e: UIEvent):void {
           this.inputs?.forEach(x => x.checked=false);
           this.activeFiltersButtons?.forEach(x => {
               $(x).find("span.eh-icon").removeClass(EhFilterController.REMOVE_CLASSNAMES).addClass(EhFilterController.SPINNER_CLASSNAMES);
           })
           this.onChange(e,false);
        }

        private onChange = (e: UIEvent, addSpinner:boolean = true): void => {
            e.preventDefault();
            if (addSpinner) {
                $(e.currentTarget as HTMLInputElement).closest('.eh-accordion-ctrl--category-item').find("span.eh-icon").removeClass(EhFilterController.ARROW_UP_CLASSNAMES).addClass(EhFilterController.SPINNER_CLASSNAMES);
                $(e.currentTarget as HTMLInputElement).closest('ehel-filter--filters-selection-list').addClass("loading");
            }
            const list = this.el?.querySelectorAll(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`);
            if(list){
                list.forEach(x => { x.classList.add("loading")})
            }

            //e.stopPropagation();
            if (this.form && this.form?.dataset.snippetUrl) {
                const fd = new FormData(this.form);
                const data: any = {};
                fd.forEach((d, v) => {
                    data[v] = d.toString()
                });
                let target = e.target as HTMLElement;
                let change = ($(target).is("input[type='checkbox']") && $(target).prop('checked')) ? 'filter_selected' : ($(target).selfOrClosest('.marker-filters-clear').length)?'filters_clear':'filter_reset';
                $(this.form).data('csTrackingAjaxChange', change);
                cs.Snippet.preAjax($(this.form.parentElement as HTMLElement), target);
                $(this.form).data('csTrackingAjaxActivator', undefined);
                $(this.form).data('csTrackingIsSnippetRequest', true);
                $.ajax(this.form.dataset.snippetUrl, {method: "POST", data: data}).done((d: string) => {
                    const newDom = new DOMParser().parseFromString(d, "text/html");
                    // replace:
                    // result counts
                    // filters
                    const replaceMap: { [key: string]: HTMLElement } = {};
                    const openFilters: string[] = []
                    const openPills: string[] = []
                    this.el?.querySelectorAll(`${AccordionCtrl.ACCORDION_CATEGORY_SELECTOR}`).forEach((e: HTMLElement) => {
                            if (e.dataset.filterProperty && e.classList.contains("is-open")) {
                                openFilters.push(e.dataset.filterProperty);
                            }
                        }
                    );

                    this.el?.querySelectorAll(`.ehel-filter--filter.is-active`).forEach((e: HTMLElement) => {
                            if (e.dataset.filterProperty) {
                                openPills.push(e.dataset.filterProperty);
                            }
                        }
                    );

                    newDom.querySelectorAll(".ehel-product-finder--ajax-replace").forEach((e: HTMLElement) => {
                            if (e.dataset.replaceId) {
                                replaceMap[e.dataset.replaceId] = e;
                                e.querySelectorAll(`${AccordionCtrl.ACCORDION_CATEGORY_SELECTOR}`).forEach((e1: HTMLElement) => {

                                    if (e1.dataset.filterProperty) {
                                        // console.log(e1.dataset.filterProperty);
                                        if (openFilters.find((v) => {
                                            const rv = e1.dataset.filterProperty === v
                                            // console.log("filtering: ", e1.dataset.filterProperty, v, rv);
                                            return rv;
                                        })) {
                                            e1.classList.add("is-open");
                                            // console.log(e1);
                                        }
                                    }
                                });
                                e.querySelectorAll(`.ehel-filter--filter`).forEach((e1: HTMLElement) => {
                                    if (e1.dataset.filterProperty) {
                                        // console.log(e1.dataset.filterProperty);
                                        if (openPills.find((v) => {
                                            const rv = e1.dataset.filterProperty === v
                                            // console.log("filtering: ", e1.dataset.filterProperty, v, rv);
                                            return rv;
                                        })) {
                                            e1.classList.add("is-active");
                                            e1.parentElement?.querySelector("div")?.classList.remove("lg:ehtw-hidden")
                                        }
                                    }
                                })

                            }
                        }
                    );

                    {
                        const w: HTMLElement | null = newDom.querySelector(`.${EhFilterController.FILTER_WRAPPER}`);
                        const newUrl = w?.dataset.url
                        if (newUrl) {
                            history.pushState({}, "", newUrl);
                        }
                    }
                    {
                        const s: HTMLElement | null = newDom.querySelector(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`);
                        const t: HTMLElement | null | undefined = this.el?.querySelector(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`);
                        if (s && t) {
                            t.dataset.page = s.dataset.page
                            t.dataset.nextPage = s.dataset.nextPage
                            t.dataset.nextPageHistory = s.dataset.nextPageHistory
                            t.dataset.resultCount = s.dataset.resultCount
                        }
                    }

                    this.el?.querySelectorAll(".ehel-product-finder--ajax-replace").forEach((e: HTMLElement) => {
                            if (e.dataset.replaceId && replaceMap[e.dataset.replaceId]) {
                                $(e).replaceWith(replaceMap[e.dataset.replaceId]);
                                 const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
                                 event.replacedTarget = $(replaceMap[e.dataset.replaceId]);
                                 event.removedTarget = $(e);
                                 $(':root').trigger(event);
                            }
                        }
                    )
                    this.initDynamics();
                    this.registerDynamicControls();
                    // reiinit acordion
                    this.el?.querySelectorAll(`${eh.AccordionCtrl.ACCORDION_SELECTOR}`).forEach((e) => {
                            // $(e).trigger(AccordionCtrl.RELOAD_EVENT)
                            e.dispatchEvent(new Event(AccordionCtrl.RELOAD_EVENT));
                        }
                    );

                    // results
                    this.el?.querySelectorAll(`.ehel-product-finder--results-filters-container .ehel-product-card`).forEach( (e:HTMLElement) => e.remove());
                    const parent = this.el?.querySelector(`.ehel-product-finder--results-filters-container`);

                    if(parent){
                        newDom.querySelectorAll(`.ehel-product-finder--results-filters-container .ehel-product-card`).forEach((e: HTMLElement) => {
                            parent.appendChild(e);
                            const ev = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
                            ev.replacedTarget = $(e);
                            $(':root').trigger(ev);
                        });
                    }

                    this.el?.querySelectorAll(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`).forEach((e) => {
                            // $(e).trigger(AccordionCtrl.RELOAD_EVENT)
                            e.dispatchEvent(new Event(EhLoadMoreController.RELOAD_EVENT));
                        }
                    );
                    // pager or refresh pager
                    if (list) {
                        list.forEach(x => {
                            x.classList.add("loading")
                        })
                    }
                });
            }
        }

        private removeFilter = (e: UIEvent): void => {
            let button = e.currentTarget as HTMLElement;
            e.preventDefault();
            e.stopPropagation();
            $(button).find("span.eh-icon").removeClass(EhFilterController.REMOVE_CLASSNAMES).addClass(EhFilterController.SPINNER_CLASSNAMES);
            const paramName = button.dataset.paramName
            const paramValue = button.dataset.paramValue
            if (paramName && paramValue) {
                this.el?.querySelectorAll("input").forEach((e: HTMLInputElement) => {
                    if (e.dataset.paramName == paramName && e.value == paramValue) {
                        e.checked = false;
                        e.dispatchEvent(new Event('change'));
                    }
                });
            }
        }

        private doFilterClick = (e: UIEvent): void => {
            let filter = e.currentTarget as HTMLElement;
            e.preventDefault();
            e.stopPropagation();

            this.vm?.showFilter(filter)
        }

        private onOpenMenuClick = (e: UIEvent): void => {
            e.preventDefault();
            e.stopPropagation();
            this.vm?.openMenu();
        }

        private onCloseMenuClick = (e: UIEvent): void => {
            e.preventDefault();
            e.stopPropagation();
            this.vm?.closeMenu();
        }

        private onScrollPageClicked = (e: MouseEvent | TouchEvent): void => {
            let el = e.target as HTMLElement;
            let x = $(el).closest(`.${EhFilterController.FILTER_CLASSNAME}`);
            if (x.length == 0) {
                this.vm?.clickRoot(el);
            }
        }
    }


    class EhFilterViewModel {

        private static HIDDEN_CLASSNAME: string = 'lg:ehtw-hidden';

        private static LEFT_FULL_CLASSNAME: string = '-ehtw-left-2screenw';
        private static LEFT_NONE_CLASSNAME: string = 'ehtw-left-none';

        private static ARROW_UP_CLASSNAME: string = 'icon-ehel-arrow-up';
        private static ARROW_DOWN_CLASSNAME: string = 'icon-ehel-arrow-down';

        private static ACTIVE_CLASSNAME: string = 'is-active';

        private lastOpen?: string;

        constructor(
            private filters: () => NodeListOf<HTMLElement> | null | undefined,
            private menu: HTMLElement
        ) {
            setTimeout(() => {
                this.init();
            }, 20);

        }

        private init(): void {
        }

        public openMenu(): boolean {

            this.menu.classList.remove(EhFilterViewModel.LEFT_FULL_CLASSNAME);
            this.menu.classList.add(EhFilterViewModel.LEFT_NONE_CLASSNAME);

            eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll');

            return true;

        }

        public closeMenu(): boolean {

            this.menu.classList.add(EhFilterViewModel.LEFT_FULL_CLASSNAME);
            this.menu.classList.remove(EhFilterViewModel.LEFT_NONE_CLASSNAME);

            eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll');

            return true;

        }

        public showFilter(filter: HTMLElement | null): boolean {

            if (filter) {
                this.lastOpen = filter.dataset.filterProperty;
                if (filter.classList.contains(EhFilterViewModel.ACTIVE_CLASSNAME)) {
                    this.reset();
                } else {
                    this.reset();

                    filter.classList.add(EhFilterViewModel.ACTIVE_CLASSNAME);

                    const icon = filter.querySelector(`.${EhFilterController.ICON_CLASSNAME}`);
                    if (icon) {
                        icon.classList.remove(EhFilterViewModel.ARROW_DOWN_CLASSNAME);
                        icon.classList.add(EhFilterViewModel.ARROW_UP_CLASSNAME);
                    }

                    const wrapper = filter.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`);

                    if (wrapper) {

                        const list = wrapper.querySelector(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`);

                        if (list) {
                            list.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
                        }
                    }
                }

            } else {
                this.reset();
            }
            return true;

        }

        public reset() {
            this.filters()?.forEach(filter => {
                filter.classList.remove(EhFilterViewModel.ACTIVE_CLASSNAME);

                const icon = filter.querySelector(`.${EhFilterController.ICON_CLASSNAME}`);
                if (icon) {
                    icon.classList.remove(EhFilterViewModel.ARROW_UP_CLASSNAME);
                    icon.classList.add(EhFilterViewModel.ARROW_DOWN_CLASSNAME);
                }

                const wrapper = filter.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`);

                if (wrapper) {

                    const list = wrapper.querySelector(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`);

                    if (list) {
                        list.classList.add(EhFilterViewModel.HIDDEN_CLASSNAME);
                    }
                }
            });
        }

        public clickRoot(elem: HTMLElement | null) {
            const filterActive = elem?.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`);
            if (!filterActive) {
                this.reset();
            }
        }
    }
}
