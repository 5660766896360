namespace eh {
  export class TooltipController {
    public static TOOLTIP_DATA_ATTR: string = 'data-tooltip-text';

    static init($base: JQuery<HTMLElement>): void {
      if($base.length > 0) {
        new TooltipController($base[0]);
      };
    }

    private tooltips: NodeListOf<HTMLElement> | null | undefined;
    private vm: TooltipViewModel | null | undefined = null;

    constructor(private readonly base: HTMLElement | null) {
      this.tooltips = this.base?.querySelectorAll(`[${TooltipController.TOOLTIP_DATA_ATTR}]`);
      if (!this.tooltips) {
        return;
      }
      this.vm = new TooltipViewModel();
      this.init();
    }

    private init(): void {
      this.registerControls();
    }

    private registerControls(): void {
      this.tooltips?.forEach(tooltip => {
        tooltip.addEventListener('mouseenter', this.onMouseEnter);
        tooltip.addEventListener('mouseleave', this.onMouseLeave);
        tooltip.addEventListener('mousemove', this.onMouseMove);
      });
    }

    private onMouseEnter = (event: MouseEvent): void => {
      this.vm?.onMouseEnter(event);
    }

    private onMouseLeave = (event: MouseEvent): void => {
      this.vm?.onMouseLeave(event);
    }

    private onMouseMove = (event: MouseEvent): void => {
      this.vm?.onMouseMove(event);
    }
  }

  class TooltipViewModel {
    constructor() { 
    }

    public onMouseEnter (event: MouseEvent) {
      const target = event.currentTarget as HTMLElement;
      target.classList.add('before:!ehtw-visible');

      target.style.setProperty('--tooltip-top', `${event.clientY - 90}px`);
      target.style.setProperty('--tooltip-left', `${event.clientX - 10}px`);
    }

    public onMouseMove (event: MouseEvent) {
      const target = event.currentTarget as HTMLElement;
      target.style.setProperty('--tooltip-top', `${event.clientY  - 90}px`);
      target.style.setProperty('--tooltip-left', `${event.clientX - 10}px`);
    }

    public onMouseLeave (event: MouseEvent) {
      const target = event.currentTarget as HTMLElement;
      target.classList.remove('before:!ehtw-visible');
    }
  }
}